<template>
  <div class="d-flex align-items-center row">
    <label class="mr-3 mb-0 d-none d-md-block mb-3">{{ $t(title) }}
      <info-tooltip
        :helper-text="helperText"></info-tooltip>
    </label>
    <div class="w-100 pr-1">
      <div class="col-lg-12 col-sm-12 px-0 mx-0">
        <div class="input-group date w-100 my-date-filter-main" style="position: relative;">
          <date-pick
              v-model="modelProxy"
              :format="format"
              :isDateDisabled="dateRange"
              :inputAttributes="{class: 'form-control form-control-sm rounded-full w-100', readonly: true}"
              :weekdays="weekdays"
              :months="months"
          ></date-pick>
          <span class="date-icon"><i class="flaticon2-calendar-9 text-muted"></i></span>
        </div>
      </div>
      <span class="form-text text-muted" v-if="hint">{{ hint }}</span>
      <p class="text-danger pt-1 px-3 block" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import * as moment from 'moment'
import {MOMENT_DATE_FORMAT_FOR_KT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

/*
* Recursively merge properties of two objects
*/
function mergeRecursive(obj1, obj2) {

  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if ( obj2[p].constructor==Object ) {
        obj1[p] = mergeRecursive(obj1[p], obj2[p]);

      } else {
        obj1[p] = obj2[p];

      }

    } catch(e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];

    }
  }

  return obj1;
}

Vue.component('date-pick', mergeRecursive(DatePick, {
  methods: {
    addCloseEvents() {
      if (!this.closeEventListener) {
        this.closeEventListener = e => this.inspectCloseEvent(e);
        ['click', 'keyup'].forEach(
            eventName => document.addEventListener(eventName, this.closeEventListener)
        );
      }
    },
  }
}));

export default {
  name: "DatePickerInput",
  props: {
    helperText: {
      default: 'Başlangıç Tarihi',
      required: false,
      type: String,
    },
    startDate: {
      default: function(){
        return moment('01/01/1000', MOMENT_SYSTEM_DATE_FORMAT);
      },
      required: false,
    },
    endDate: {
      default: function(){
        return moment('31/12/3000', MOMENT_SYSTEM_DATE_FORMAT);
      },
      required: false,
    },
    name: {},
    model: {},
    title: {},
    error: {},
    hint: {},
    placeholder: {},
    required: {
      default: true
    },
    includeLabel: {
      default: true,
    }
  },
  components: {
    DatePick,
    InfoTooltip
  },
  data() {
    return {
      format: MOMENT_SYSTEM_DATE_FORMAT,
      weekdays: [this.$t("dateTime.mon"), this.$t("dateTime.tue"), this.$t("dateTime.wed"), this.$t("dateTime.thu"), this.$t("dateTime.fri"), this.$t("dateTime.sat"),this.$t("dateTime.sun")],
      months: [this.$t("dateTime.january"), this.$t("dateTime.february"), this.$t("dateTime.march"), this.$t("dateTime.april"), this.$t("dateTime.may"), this.$t("dateTime.june"), this.$t("dateTime.july"), this.$t("dateTime.august"), this.$t("dateTime.september"), this.$t("dateTime.october"), this.$t("dateTime.november"), this.$t("dateTime.december")],
    }
  },
  computed: {
    modelProxy: {
      set(value) {
        this.$emit('update:model', moment(value, MOMENT_SYSTEM_DATE_FORMAT))
      },
      get() {
        if (this.model == null) {
          return moment(Date()).format(MOMENT_DATE_FORMAT_FOR_KT);
        }
        return moment(this.model).format(MOMENT_DATE_FORMAT_FOR_KT);
      },
    }
  },
  methods: {
    dateRange: function(selectedDate){
      return !(selectedDate > this.startDate.toDate() && selectedDate < this.endDate.toDate());
      // return moment(selectedDate).isBetween(this.endDate, this.startDate);
    }
  }
}
</script>

<style lang="css">
.vdpHeadCell {
  padding: .3em .1em 1.8em !important;
}
.vdpCell.selected .vdpCellContent {
  background: #3699ff !important;
}
.vdpCell.selectable:hover .vdpCellContent {
  background: #3699ff !important;
}
.vdpHeader {
  background: #fff;
}
.vdpClearInput {
  display: none !important;;
}
.vdpClearInput:before {
  content: none !important;
}
.vdpComponent{
  width: 100% !important;
}


.date-icon {
  position: absolute;
  top: 25%;
  right: 10%;
}
</style>
