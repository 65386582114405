<template>
  <div class="d-flex align-items-center row mx-0 px-2" style="flex-grow: 1;">
    <label class="mb-0 d-none d-md-block pb-3" :for="name">
      {{ $t(title) }}
      <info-tooltip
        :helper-text="helperText"
        v-if="helperText"
      ></info-tooltip>
    </label>
    <div class="s-input w-100">
      <input :name="name" type="text" class="form-control form-control-sm w-100 max-width-100 rounded-full" :placeholder="$t(placeholder)"
             v-model="modelProxy"
             id="kt_datatable_search_query">
      <span>
        <i class="flaticon2-search-1 text-muted"></i>
      </span>
    </div>
  </div>

</template>

<script>
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import * as moment from "moment";
import {MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
export default {
  name: "InputFilter",
  props: {
    title: {},
    name: {},
    model: {},
    'placeholder':{
        default: "Search"
    },
    helperText: {
      default: null,
      required: false,
      type: String,
    }
  },
  data() {
    return {
      value: ''
    }
  },
  components: {
    InfoTooltip
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value)
      },
    },
  }
}
</script>

<style scoped>
.max-width-100 {
  max-width: available !important;
  background-color: #fff;

}

.s-input {
  position:relative;
}

.s-input span {
  position: absolute;
  top:20%;
  right: 3%;
}


</style>